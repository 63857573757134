import { MasterCodeMaintenanceConstant } from "presentation/constant/MasterCode/MasterCodeMaintenanceConstant";
import { useMasterCodeMaintenanceVM } from "presentation/hook/MasterCode/useMasterCodeMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useMasterCodeMaintenanceTracked } from "presentation/store/MasterCode/MasterCodeMaintenanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const MasterCodeEditPanelTitleBar: React.FC = () => {
    const [masterCodeState] = useMasterCodeMaintenanceTracked();
    const masterCodeVM = useMasterCodeMaintenanceVM();
    const messageBarVM = useMessageBarVM();
    const {currentSelectedRow, masterState} = masterCodeState;
    const {editingEntity, isAdd, isEditable, isRead} = masterState;
    
    const [anainfoState] = useANAInfoTracked();
    const {allowCreate, allowUpdate} = anainfoState;
    const [isLoading, setIsLoading] = useState(false);
    const currentEntity = isRead? currentSelectedRow : editingEntity;

    const handleReset = useCallback(async () => {
        masterCodeVM.onReset();
    }, [masterCodeVM]);

    const handleEdit = useCallback(()=>{
        masterCodeVM.onEditClick(currentEntity);
    },[currentEntity, masterCodeVM])

    const handleSave = useCallback(async () => {
        setIsLoading(true);
        try {
            masterCodeVM.onSaveClicked();
            const res = await masterCodeVM.onSave(currentEntity,isAdd);
            if(!!!res || !res.success){
                messageBarVM.showWarining(res?.data??'Save data failed.');
            }else {
                masterCodeVM.onClose();
                setIsLoading(true);
            }
        } catch (error) {

        }finally{
            setIsLoading(false)
        }
        
    },[currentEntity, isAdd, messageBarVM, masterCodeVM]);

    return <Sidebarheader style={{width: '100%'}}>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{title: MasterCodeMaintenanceConstant.CATEGORY},
                { title: MasterCodeMaintenanceConstant.TITLE }]}onCurrentClick={()=>{}}>
            </HPHBreadcrumb>
        </Breadcrumb>
        <StyledAction>
            {!(isAdd || isEditable) && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />}
            {(isAdd || isEditable) && <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />}
            {<IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={masterCodeVM.onClose} />}
            {(isAdd || isEditable) && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />}
        </StyledAction>
    </Sidebarheader>
}

export default memo(MasterCodeEditPanelTitleBar);