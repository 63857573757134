import { MasterCodeMaintenanceConstant } from "./MasterCodeMaintenanceConstant";

const SCREEN_CONSTANT = MasterCodeMaintenanceConstant;
export const INITIAL_MASTER_CODE_MAINTENANCE_COL_DEF: any[] = [
    {
        headerName: SCREEN_CONSTANT.CODE_TYPE,
        field: "masterType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 200,

        //pinned: "left",
        //checkboxSelection: true,
        //headerCheckboxSelection: true,

    },
    {
        headerName: SCREEN_CONSTANT.CODE,
        field: "masterCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 120,
    },
    {
        headerName: SCREEN_CONSTANT.CODE_DESC,
        field: "masterDesc",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 130,
    },
    {
        headerName: SCREEN_CONSTANT.UC_IND,
        field: "ucInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 110,
    },
    {
        headerName: SCREEN_CONSTANT.CHARGE_IND,
        field: "chargeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 140,
    },
]
